import '../App.css';
import { Container, Row, Col } from 'react-bootstrap';
const Footer = () => {
	return (
		<Container fluid className='footerCont'>
			Designed & Built by Prathap Reddy &trade;
		</Container>
	);
};

export default Footer;
